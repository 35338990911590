import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const DialogFinalizar = ({ tipo, open, setOpen }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const finalizar = async () => {


        try {
            // const response = await getProyectosRecientes();
            // console.log("aa", response)
            // setProyectos(response.data)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullScreen
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={12}
                        gap={2}
                    >
                        {/* <Grid container xs={2} style={{ marginRight: 10 }}>
                            <GroupAddIcon sx={{ fontSize: 80 }} color='azul' />
                        </Grid> */}
                        <Grid container xs={12}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography variant="h4" gutterBottom>
                                Finalizar {tipo}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Esta seguro que desea finalizar {tipo}?
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                    >
                        <Button variant="outlined" sx={{ mt: 2, p: 1 }} onClick={() => handleClose()}> Cancelar Accion</Button>
                        <Button variant="contained" sx={{ mt: 2, p: 1 }} onClick={() => finalizar()}> Finalizar {tipo}</Button>

                    </Grid>



                </DialogContent>
            </Dialog>

        </>
    )
}
