import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DialogFinalizar } from './DialogFinalizar';


export const ListaProyectos = ({ proyectos, finalizado }) => {

    const Navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const cambiarFormatoFecha = (fechaCompleta) => {
        // Dividimos la parte de la fecha y la hora
        const [fechaISO, hora] = fechaCompleta.split(' ');

        // Dividimos la fecha ISO en [año, mes, día]
        const [year, month, day] = fechaISO.split('-');

        // Creamos el nuevo formato de fecha "día/mes/año"
        const fechaFormateada = `${day}/${month}/${year}`;

        // Retornamos el nuevo formato completo: "fecha ISO fecha formateada - hora"
        return `${fechaFormateada} - ${hora}`;
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={11}
            gap={2}
            className='load fondo-blanco'
        >
            {proyectos.map((info, index) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12} md={3.5}
                    className='borde-redondo sombra-corta card-articulo load'
                    padding={2}
                    key={index}
                    style={info.finalizado == 1 ? { backgroundColor: '#c9f7cb' } : null}
                // onClick={() => Navigate("/trabajos/" + info.id)}
                >
                    <Grid item xs={info.finalizado == 1 ? 10 : 12}>
                        <Typography variant="h5" style={{ textAlign: 'left', marginBottom: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {info.nombre}
                        </Typography>
                    </Grid>
                    {info.finalizado == 1 ?
                        <Grid item xs={2} className='no-select'>
                            <Tooltip
                                title="Finalizado"
                                placement="top" color='error'
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <CheckCircleIcon color='success' fontSize='medium' />
                            </Tooltip>
                        </Grid>
                        : ''}
                    <div className="separador-azul" style={{ marginBottom: '0.5em' }}></div>

                    <Grid item xs={12} style={{ textAlign: 'left', marginBottom: '0.5em' }}>
                        <Typography variant="caption" style={{ textAlign: 'left', marginBottom: '1em', fontWeight: '400' }}>
                            Fecha de creación: {cambiarFormatoFecha(info.fecha)}
                        </Typography>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Proyecto Nº{info.idProyecto}
                        </Typography>

                        {info.finalizado == 0
                            ? <>
                                <ButtonGroup variant="outlined" aria-label="Basic button group">
                                    <Button color='azul' onClick={() => Navigate("/trabajos/" + info.idProyecto)} size='small'>Ver</Button>
                                    <Button color='azul' size='small' onClick={() => handleClickOpen()}>Finalizar</Button>
                                </ButtonGroup>
                            </>

                            : <>
                                <ButtonGroup variant="outlined" aria-label="Basic button group">
                                    <Button variant="outlined" color='azul' onClick={() => Navigate("/trabajos/" + info.idProyecto)} size='small'>Ver</Button>
                                    <Button variant="outlined" color='azul' size='small'>Facturar</Button>
                                </ButtonGroup>
                            </>
                        }
                    </Grid>
                </Grid>

            ))
            }

            <DialogFinalizar open={open} setOpen={setOpen} tipo={"proyecto"}></DialogFinalizar>

        </Grid >
    )
}
