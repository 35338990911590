import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CameraAltIcon from '@mui/icons-material/CameraAlt';



import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { Camara } from '../components/Camara';
import { postAriculo } from '../services/apis';
import { SubHeader } from '../components/SubHeader';

export const AnadirStock = () => {
  const Navigate = useNavigate();
  const param = useParams();


  const [correcto, setCorrecto] = useState(false);

  const [camara, setCamara] = useState(false);
  const [barcode, setBarcode] = useState(null);
  const [articulo, setArticulo] = useState(
    {
      nombre: '',
      codBarras: '',
      referencia: '',
      precio: null,
      stock: null
    });

  const handleClose = () => {
    setCorrecto(false);
    setArticulo({
      nombre: '',
      codBarras: '',
      referencia: '',
      precio: null,
      stock: null
    })
  };

  const handledOnChange = (event) => {
    const { name, value } = event.target; // Extraer el nombre y el valor del evento

    // console.log(value);

    switch (name) {
      case "codBarras":
        setArticulo(prevArticulo => ({
          ...prevArticulo,
          codBarras: value // Actualiza la propiedad codBarras
        }));
        break;
      case "ref":
        setArticulo(prevArticulo => ({
          ...prevArticulo,
          referencia: value // Actualiza la propiedad referencia
        }));
        break;
      case "nombre":
        setArticulo(prevArticulo => ({
          ...prevArticulo,
          nombre: value // Actualiza la propiedad nombre
        }));
        break;
      case "precio":
        setArticulo(prevArticulo => ({
          ...prevArticulo,
          precio: value // Actualiza la propiedad nombre
        }));
        break;
      case "stock":
        setArticulo(prevArticulo => ({
          ...prevArticulo,
          stock: parseInt(value)
        }));
        break;
      default:
        break;
    }
  };


  const anadirArticulo = async (articulo) => {
    try {
      const response = await postAriculo(articulo);
      // console.log("aa", response.articulo)

      if (response.success) {
        setCorrecto(true)
        setArticulo(response.articulo)
      }

    } catch (error) {
      console.error("Error:", error);
    }
  }


  useEffect(() => {
    setArticulo(prevArticulo => ({
      ...prevArticulo,
      codBarras: param.codBarras
    }));
  }, [])


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
      xs={12}
      className='load'
      gap={2}
      sx={{ pb: 10 }}
    >
      <Header></Header>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={11} sm={11} md={10} xl={8}
      >
        <SubHeader></SubHeader>

      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={11} sm={11} md={10} xl={8}
        className='borde-redondo sombra-corta'
        padding={2}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          md={11}
        >
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <LibraryAddIcon sx={{ fontSize: 50, m: 1 }} color='verde' />
            <Typography variant="h4">
              Añadir Articulo
            </Typography>
          </Grid>


          <div className="separador"></div>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="start"
          xs={12}
          gap={1}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            xs={12} md={5}
            gap={0.5}
          >
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Escanear codigo de barras
            </Typography>
            <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ marginBottom: '1em', padding: '0.9em' }}>
              <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
            </Button>
            <Camara
              camara={camara}
              setCamara={setCamara}
              barcode={barcode}
              setBarcode={setBarcode}
              // buscarArticulo={buscarArticulo}
              articulo={articulo}
              setArticulo={setArticulo}
            ></Camara>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            xs={12} md={5}
            gap={0.5}
          >

            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Codigo de barras
            </Typography>

            <TextField value={articulo.codBarras} name='codBarras' type='text' id="outlined-basic" label="Codigo de barras" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

            <div className="separador-2"></div>


            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Codigo de referencia
            </Typography>

            <TextField value={articulo.referencia} name='ref' id="outlined-basic" label="Codigo de referencia" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

            <div className="separador-2"></div>

            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Nombre del articulo:
            </Typography>

            <TextField value={articulo.nombre} name='nombre' id="outlined-basic" label="Nombre del articulo" variant="outlined" fullWidth color='azul' onChange={handledOnChange} />

            <div className="separador-2"></div>

            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Precio (€)
            </Typography>

            <TextField value={articulo.precio} name='precio' id="outlined-basic" label="Precio" variant="outlined" fullWidth color='azul' type='number' onChange={handledOnChange} inputProps={{ step: 0.01 }} />

            <div className="separador-2"></div>

            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Stock inicial
            </Typography>

            <TextField value={articulo.stock} name='stock' id="outlined-basic" label="Cantidad" variant="outlined" fullWidth color='azul' type='number' onChange={handledOnChange} />



          </Grid>
          <div className="separador-2"></div>

          <Grid item xs={12} md={5}>
            <Button variant="outlined" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => Navigate("/")}> Cancelar </Button>
          </Grid>

          <Grid item xs={12} md={5}>
            <Button variant="contained" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => anadirArticulo(articulo)}> Añadir Stock </Button>
          </Grid>
        </Grid>

      </Grid>


      <Dialog
        open={correcto}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" style={{ textAlign: 'left' }}>
            Articulo añadido con exito
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="column"
              justifyContent="start"
              alignItems="start"
              xs={12}
              gap={0.5}
            >
              <Typography variant="h6" style={{ textAlign: 'left' }}>
                Nombre: {articulo.nombre}
              </Typography>
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                Codigo de barras: {articulo.codBarras}
              </Typography>
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                Codigo de referencia: {articulo.referencia}
              </Typography>
              <Typography variant="h6" style={{ textAlign: 'left' }}>
                Precio: {articulo.precio}€
              </Typography>
              <Typography variant="h6" style={{ textAlign: 'left' }}>
                Stock inicial: {articulo.stock}
              </Typography>


            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              setArticulo({
                nombre: '',
                codBarras: '',
                referencia: '',
                precio: 0,
                stock: 0
              })
            }}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>



      <CerrarSesion></CerrarSesion>
    </Grid>
  )
}
