import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';




import { Header } from '../components/Header';
import { asigEmpleados, getEmplados, postTrabajos } from '../services/apis';
import { Notificacion } from '../components/Notificacion';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { SubHeader } from '../components/SubHeader';
import { Camara } from '../components/Camara';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}



export const CrearTrabajo = () => {
    // Crear un evento de teclado para la tecla "Tab"
    let tecla = new KeyboardEvent('keydown', {
        key: 'Tab',
        code: 'Tab',
        keyCode: 9,        // keyCode para Tab
        charCode: 9,
        which: 9,
        bubbles: true // Hacer que el evento burbujee
    });

    const Navigate = useNavigate();
    const firstInputRef = useRef(null);
    const secondInputRef = useRef(null);
    const param = useParams();

    const [trabajo, setTrabajo] = useState({});

    const [idProyecto, setIdProyecto] = useState("");

    const [notificacion, setNotificacion] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [severity, setSeverity] = useState("success")

    const [cantidad, setCantidad] = useState(1);
    const [codBarras, setCodBarras] = useState("");
    const [articulos, setArticulos] = useState([]);
    const [articulo, setArticulo] = useState(
        {
            nombre: "Articulo ",
            codBarras: codBarras,
            cantidad: cantidad
        });
    const [nombreTr, setNombreTr] = useState("")
    const [finalizar, setFinalizar] = useState(false);

    const [openCamara, setOpenCamara] = useState(false);
    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);
    const [timer, setTimer] = useState(null);


    const [empleados, setEmpleados] = useState([])
    const [empleadosAsig, setEmpleadosAsig] = useState([])
    const [tempEmplados, setTempEmplados] = useState([])
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);




    // -------------------------------------------------HANDLEDS----------------------------------------
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handledOnChange = (event, id) => {

        switch (event.target.name) {
            case "barcode":
                setCodBarras(event.target.value);
                // Cancelar el temporizador existente si hay alguno
                if (timer) {
                    clearTimeout(timer);
                }

                // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
                setTimer(setTimeout(() => {
                    secondInputRef.current.focus();
                }, 500));
                break;

            case "cantidad":
                if (event.target.value <= 0) {
                    setCantidad(1)
                    break;
                } else {
                    setCantidad(event.target.value);
                    break;
                }

            case "nombre":
                setNombreTr(event.target.value);
                break;

            case "horas":
                setEmpleadosAsig(prevEmpleados => {
                    const temp = prevEmpleados.find(empleado => empleado.id === id);
                    if (temp) {
                        temp.horas = event.target.value < 0 ? 0 : event.target.value; // Controla que horas no sea menor a 0
                    }
                    return [...prevEmpleados]; // Retorna un nuevo array para que React detecte el cambio
                });

            default:
                break;

        }
    };

    const handleClose = () => {
        setFinalizar(false);
    };


    // -------------------------------------------------EMPLADOS----------------------------------------
    const cargarEmplados = async () => {
        try {
            const response = await getEmplados();
            // console.log(response);

            if (response.success) {
                setEmpleados(response.data);
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }

    const añadirEmpleados = (empleadosArray) => {

        setEmpleadosAsig(prevEmpleadosAsig => {
            let nuevosEmpleadosAsignados = [...prevEmpleadosAsig]; // Copiar el array de empleadosAsignados actual
            let nuevosEmpleados = [...empleados]; // Copiar el array de empleados actual

            // Iterar sobre el array de empleados a añadir
            empleadosArray.forEach(empleado => {
                // Buscar el empleado en el array de empleados por id
                const index = nuevosEmpleados.findIndex(emp => emp.id === empleado.id);

                if (index !== -1) {
                    // Eliminar el empleado de empleados (Array de empleados)
                    const [empleadoAEliminar] = nuevosEmpleados.splice(index, 1);

                    // Añadir el empleado al array de empleadosAsignados
                    nuevosEmpleadosAsignados = [...nuevosEmpleadosAsignados, empleadoAEliminar];
                }
            });

            // Actualizar el estado de empleados y empleadosAsignados
            setEmpleados(nuevosEmpleados);
            return nuevosEmpleadosAsignados;
        });
        setTempEmplados([]);
        setPersonName([]);

    };

    const quitarEmpleado = (id) => {
        setEmpleados(prevEmpleados => {
            // Buscar el empleado en el array de empleadosAsignados por id
            const empleado = empleadosAsig.find(emp => emp.id === id);

            if (empleado) {
                // Eliminar el empleado de empleadosAsignados
                const nuevosEmpleadosAsignados = empleadosAsig.filter(emp => emp.id !== id);

                // Añadir el empleado al array de empleados (Array de empleados)
                const nuevosEmpleados = [...prevEmpleados, empleado];

                // Actualizar ambos arrays
                setEmpleadosAsig(nuevosEmpleadosAsignados);
                return nuevosEmpleados;
            }

            return prevEmpleados;  // Retorna el array sin cambios si no se encuentra el empleado
        });
    };


    // -------------------------------------------------ARTICULOS----------------------------------------
    const añadirArticulo = (articulo, cantidad, codBarras) => {
        cantidad = parseInt(cantidad);
        if (codBarras !== "") {
            setArticulos((prevArticulos) => {
                // Buscar si ya existe un artículo con el mismo codBarras
                const articuloExistente = prevArticulos.find((item) => item.codBarras === codBarras);

                if (articuloExistente) {
                    // Si ya existe, sumar la cantidad
                    return prevArticulos.map((item) =>
                        item.codBarras === codBarras
                            ? { ...item, cantidad: item.cantidad + cantidad } // Actualizar cantidad
                            : item
                    );
                } else {
                    // Si no existe, añadir el nuevo artículo
                    const nuevoArticulo = { ...articulo, cantidad, codBarras };
                    return [...prevArticulos, nuevoArticulo];
                }
            });
            setCantidad(1);
            setCodBarras('');
            // Cancelar el temporizador existente si hay alguno
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                firstInputRef.current.focus();
            }, 250));
            setMensaje("Articulo añadido");
            setSeverity("success")
            activarNotificacion();

        } else {
            setMensaje("Introduzca un codigo de barras");
            setSeverity("error")
            activarNotificacion();
        }

    };

    const borrarArticulo = (codBarras) => {
        // Filtrar el array para eliminar solo el artículo con el codBarras dado
        const nuevosArticulos = articulos.filter(articulo => articulo.codBarras !== codBarras);

        // Actualizar el estado con el nuevo array filtrado
        setArticulos(nuevosArticulos);
    }

    const checkArray = (arr) => {
        if (arr.length < 1) {
            setMensaje("Añade algun articulo para crear la orden de trabajo");
            setSeverity("error")
            activarNotificacion();

        } else {
            if (nombreTr == "" || nombreTr == null) {
                setMensaje("La orden de trabajo debe de tener un nombre");
                setSeverity("error")
                activarNotificacion();
                setFinalizar(false)


            } else {
                setTrabajo({
                    nombre: nombreTr,
                    articulos: articulos,
                    empleadosAsig: empleadosAsig,
                    idProyecto: idProyecto
                })
                setFinalizar(true)
            }
        }
    }


    // -------------------------------------------------VARIOS----------------------------------------
    const abrirCamara = (openCamara, camara) => {

        if (openCamara == false && camara == false) {
            setOpenCamara(true);
            setCamara(true);
        } else {
            setOpenCamara(false);
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                setCamara(false);

            }, 2000));
        }

    };

    const activarNotificacion = () => {
        // setMensaje(texto);
        // Cambiar la notificación a true
        setNotificacion(true);

        // Después de 2 segundos, volverla a false
        setTimeout(() => {
            setNotificacion(false);
        }, 3000);
    };

    const goBack = () => {
        window.history.back();
    }




    const crearTrabajo = async (trabajo) => {
        console.log("FUNC", trabajo);
        try {
            const response = await postTrabajos(trabajo);
            console.log("Respuesta", response)
            if (response.success) {
                setFinalizar(false)
                Navigate("/trabajos/" + idProyecto)
            } else {

            }


        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }
    }


    useEffect(() => {
        setIdProyecto(param.idProyecto)
        cargarEmplados();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
            >
                <SubHeader></SubHeader>
            </Grid>



            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <GroupAddIcon sx={{ fontSize: 50, m: 1 }} color='azul' />
                        <Typography variant="h4">
                            Crear Trabajo
                        </Typography>
                    </Grid>

                    <div className="separador"></div>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    md={11}
                    spacing={1}
                >
                    <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Añadir articulo
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12}>
                            <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => abrirCamara(openCamara, camara)} style={{ margin: '1em 0em', padding: '0.7em' }}>
                                <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                            </Button>
                        </Grid>

                        <Camara camara={openCamara} setCamara={setCamara} barcode={barcode} setBarcode={setBarcode}></Camara>

                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Codigo de Barras"
                                    variant="outlined"
                                    color='azul'
                                    type='text'
                                    autoFocus
                                    required
                                    onChange={handledOnChange}
                                    value={codBarras}
                                    name='barcode'
                                    inputRef={firstInputRef}

                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad'
                                    value={cantidad}
                                    onChange={handledOnChange}
                                    inputRef={secondInputRef}
                                />

                            </Grid>
                            <Button
                                color='azul'
                                variant="contained"
                                size="large"
                                fullWidth
                                style={{ marginTop: '1em', marginBottom: '1em', padding: '1em' }}
                                onClick={() => añadirArticulo(articulo, cantidad, codBarras)}
                            >
                                <AddBoxIcon color='blanco' />&nbsp;Añadir articulo
                            </Button>
                        </FormControl>

                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        md={6}
                        gap={1}
                        style={{ maxHeight: '55vh', overflow: 'auto' }}
                    >
                        {articulos.length > 0 ? (((
                            articulos.map((info, index) => (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={10} md={11}
                                >
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                                            {info.nombre + (index + 1)}
                                        </Typography>
                                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                                            Cod: {info.codBarras}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
                                            Cantidad: {info.cantidad}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <CancelIcon className='pointer no-select' color='disabled' onClick={() => borrarArticulo(info.codBarras)} />
                                    </Grid>

                                    <div className="separador"></div>
                                </Grid>

                            ))

                        ))) : ((<Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12} md={12}
                        >
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" style={{ textAlign: 'left' }}>
                                    No hay articulos
                                </Typography>
                            </Grid>
                        </Grid>
                        ))}
                    </Grid>

                    <div className="separador-2"></div>

                    <div className="separador"></div>

                    <Grid item xs={12} style={{ marginTop: '1em' }}>
                        <Typography variant="h5" style={{ textAlign: 'left' }} gutterBottom>
                            Nombre de trabajo:
                        </Typography>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nombre de trabajo"
                            variant="outlined"
                            color='azul'
                            type='text'
                            name='nombre'
                            value={nombreTr}
                            onChange={handledOnChange}
                            required
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <div className="separador-2"></div>

                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12}
                            >
                                <Typography variant="h5" style={{ textAlign: 'left' }}>
                                    Empleados y horas:
                                </Typography>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    xs={12} md={6} xl={5}
                                    gap={1}
                                >
                                    <Grid item xs={12} md={5} xl={6}>
                                        <FormControl sx={{ m: 0, width: '100%' }}>
                                            <InputLabel id="demo-multiple-name-label">Añadir empleados</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={personName}
                                                onChange={handleChange}
                                                input={<OutlinedInput label="Añadir empleados" />}
                                                MenuProps={MenuProps}
                                            >
                                                {empleados.map((empleado) => (
                                                    <MenuItem
                                                        key={empleado.id}
                                                        value={empleado.id}
                                                        style={getStyles(empleado.nombre, personName, theme)}
                                                        onClick={() => tempEmplados.push(empleado)}
                                                    >
                                                        {empleado.nombre + ' ' + empleado.apellidos}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5} xl={2.5}>
                                        <Button fullWidth color='azul' variant="outlined" size="large" sx={{ pt: 1.7, pb: 1.7 }} onClick={() => añadirEmpleados(tempEmplados)}><AddIcon />Añadir</Button>

                                    </Grid>

                                </Grid>


                            </Grid>
                            <div className="separador-2"></div>

                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            gap={1}
                        >
                            {empleadosAsig.map((info, index) => (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Grid item xs={3} md={4} xl={2}>
                                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                                            {info.nombre + ' ' + info.apellidos}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="end"
                                        alignItems="center"
                                        xs={8} md={8} xl={8}
                                        gap={1}
                                    >
                                        <Grid item xs={4} md={4} xl={2}>
                                            <TextField
                                                fullWidth
                                                id="outlined-basic"
                                                label="Horas"
                                                variant="outlined"
                                                color='azul'
                                                type='number'
                                                name='horas'
                                                value={info.horas}
                                                onChange={(event) => handledOnChange(event, info.id)}
                                                inputProps={{ step: 0.5 }}
                                            />
                                        </Grid>

                                        <Button color='azul' variant="text" size="large" sx={{ pt: 1.7, pb: 1.7 }} onClick={() => quitarEmpleado(info.id)}><ClearIcon /> Quitar </Button>

                                    </Grid>
                                    <div className="separador"></div>
                                    {/* {index == (empleados.length - 1) ? '' : <div className="separador"></div>} */}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Button variant="outlined" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => goBack()}> Cancelar </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" size="large" color='azul' fullWidth sx={{ p: 1.5 }} onClick={() => checkArray(articulos)}><GroupAddIcon color='blanco' />&nbsp;Crear trabajo </Button>
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>



                {/* ---------------------------------------------------------------------------NOTIFICACIONES Y DIALOGO----------------------------------------------------------------------------- */}

                <Notificacion notificacion={notificacion} mensaje={mensaje} severity={severity}></Notificacion>


                <Dialog
                    open={finalizar}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className='fondo-blanco'
                    >
                        {"Crear Trabajo"}
                    </DialogTitle>
                    <DialogContent
                        className='fondo-blanco'
                    >
                        <DialogContentText id="alert-dialog-description">
                            Desea crear la orden de trabajo?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        className='fondo-blanco'
                    >
                        <Button onClick={handleClose} color='azul' size="medium" variant="outlined">Cancelar</Button>
                        <Button onClick={() => crearTrabajo(trabajo)} autoFocus color='azul' variant="contained" size="medium">
                            Crear Trabajo
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
