import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CameraAltIcon from '@mui/icons-material/CameraAlt';



export const Camara = ({ camara, setCamara, barcode, setBarcode, buscarArticulo, articulo, setArticulo }) => {

    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);

    // Configuración de la cámara
    const videoConstraints = {
        facingMode: "environment", // Usar la cámara trasera
    };

    // Método para capturar la imagen de la cámara
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            scanBarcode(imageSrc); // Intentar escanear el código de barras
        }
    }, [webcamRef]);

    // Método para escanear el código de barras
    const scanBarcode = async (imageSrc) => {
        setIsScanning(true);
        const reader = new BrowserMultiFormatReader();
        try {
            const result = await reader.decodeFromImageUrl(imageSrc);
            setBarcode(result.getText());
            setArticulo(prevArticulo => ({
                ...prevArticulo,
                codBarras: result.getText()
            }));
            buscarArticulo("codBar", result.getText());
        } catch (err) {
            console.error("Error escaneando el código de barras:", err);
            setBarcode(null);
        }
        setIsScanning(false);
    };


    return (
        <>
            <Collapse in={camara}>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    style={{ width: "100%", height: "auto" }}
                    className='borde-redondo'
                />
                <Button color='azul' variant="contained" size="large" fullWidth onClick={capture} style={{ padding: '0.7em' }}> <QrCodeScannerIcon fontSize='large' />&nbsp;Escanear codigo </Button>

            </Collapse >
        </>
    )
}
