import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://www.inelvan.com/stockapp/WS/'

// ----------------------------------------------------------TRABAJOS------------------------------------------
// --------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------GET------------------------------------------
export const getTrabajo = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                // idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getTrabajos = async (idProyecto) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idProyecto: idProyecto
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getProyectos = async () => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                // idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getTrabajosRecientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                recientes: 1
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getProyectosRecientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                recientes: 1
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEditarTrabajo = async (idTrabajo) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idTrabajo: idTrabajo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getArticulo = async (evento, value) => {
    console.log("AAAAAAAAAAAAA", evento, value);

    try {
        const response = await axios.get(baseUrl + 'stock.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                evento: evento,
                value: value
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getArticulos = async () => {

    try {
        const response = await axios.get(baseUrl + 'stock.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                todos: true
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEmplados = async () => {

    try {
        const response = await axios.get(baseUrl + 'usuarios.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                todos: true
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEmpladosAsig = async (idTrabajo) => {

    try {
        const response = await axios.get(baseUrl + 'usuarios.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                asignados: true,
                idTrabajo: idTrabajo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


// ----------------------------------------------------------POST------------------------------------------
export const postTrabajos = async (trabajo) => {
    console.log("APIS", trabajo);
    
    try {
        const response = await axios.post(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: trabajo
        });
        // console.log("aaaa", response);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postProyectos = async (nombre) => {
    try {
        const response = await axios.post(baseUrl + 'proyectos.php?', {
            token: getCookie('PHPSESSID'),
            nombre: nombre
        });
        // console.log("aaaa", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postAriculo = async (articulo) => {
    // console.log("ARTICULO",articulo)
    try {
        const response = await axios.post(baseUrl + 'stock.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
        });
        // console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const asigEmpleados = async (empleados, idTrabajo) => {
    // console.log("empleados",empleados)
    try {
        const response = await axios.post(baseUrl + 'emplados.php?', {
            token: getCookie('PHPSESSID'),
            data: empleados,
            asignar: true,
            idTrabajo: idTrabajo
        });
        // console.log("APIS", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.error);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// ----------------------------------------------------------PUT-------------------------------------------------------
export const finalizarTrabajo = async (idTrabajo, articulos, horas) => {
    try {
        console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", articulos)

        const response = await axios.put(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: articulos,
            idTrabajo: idTrabajo,
            horas: horas
        });
        console.log("aaaa", response.success);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error.response);
        return null;
    }
};

export const updateStock = async (articulo) => {
    try {
        console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", articulo)

        const response = await axios.put(baseUrl + 'stock.php?', {
            token: getCookie('PHPSESSID'),
            data: articulo,
        });
        // console.log("aaaa", response.success);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error.response);
        return null;
    }
};