import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import TuneIcon from '@mui/icons-material/Tune';

import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { getArticulos } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { Filtros } from '../components/Filtros';


export const VerStock = () => {
    const Navigate = useNavigate();


    const [articulos, setArticulos] = useState([{}])
    const [value, setValue] = React.useState([0, 999]);
    const [age, setAge] = React.useState('');
    const [filtros, setFiltros] = useState(false)

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const toggleDrawer = () => {
        setFiltros(true);
    };


    const buscarArticulo = async () => {
        try {
            const response = await getArticulos();
            console.log("aa", response.data.error)
            if (response.success) {
                setArticulos(response.data)
            }
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
            // setError(true);
        }

    }

    useEffect(() => {
        buscarArticulo();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
            sx={{ pb: 10 }}
        >
            <Header></Header>


            {/* ------------------------------------FLTROS--------------------------------- */}

            <Filtros articulos={articulos} setArticulos={setArticulos} filtros={filtros} setFiltros={setFiltros}></Filtros>

            {/* ------------------------------------ARTICULOS--------------------------------- */}

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11.5} md={10}
                // className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <SubHeader></SubHeader>

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={12}
                >

                    <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <WarehouseIcon sx={{ fontSize: 50, m: 1 }} color='azul' />
                        <Typography variant="h4">
                            Articulos
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        xs={12} md={8}
                        gap={2}
                    >
                        <Grid item xs={12} md={1.5}>
                            <Button fullWidth variant='outlined' onClick={toggleDrawer} sx={{ p: 1.8 }} color='azul'> <TuneIcon sx={{ mr: 1 }} /> Filtros</Button>

                        </Grid>

                        <Grid item xs={12} md={3}>
                            <FormControl sx={{ minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Ordenar por...</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Ordenar por..."
                                    onChange={handleChange2}
                                >
                                    {/* <MenuItem value={10}>Por defecto</MenuItem> */}
                                    <MenuItem value={20}>Alfabetico ascendente</MenuItem>
                                    <MenuItem value={30}>Alfabetico descendente</MenuItem>
                                    <MenuItem value={40}>Stock ascendente</MenuItem>
                                    <MenuItem value={50}>Stock descendente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>






                </Grid>

                <div className="separador"></div>
                <div className="separador-2"></div>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={12}
                    gap={2}
                >
                    {articulos.map((info, index) => (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            xs={12} sm={5} md={3.7} xl={2.9}
                            className='borde-redondo sombra-mini pointer card-articulo'
                            padding={1.5}
                        >
                            <Typography variant="h5" gutterBottom
                                style={{
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%'
                                }}
                            >
                                {info.nombre}
                            </Typography>

                            <div className="separador-azul"></div>
                            <Typography variant="h6">
                                Precio: {info.precio}€
                            </Typography>
                            <Typography variant="body1">
                                Cod. barras: {info.codBarras}
                            </Typography>

                            <Typography variant="caption" gutterBottom>
                                Referencia: {info.referencia}
                            </Typography>

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="start"
                                md={12}
                            >
                                <Typography variant="h6" gutterBottom>
                                    Stock: {info.stock}
                                </Typography>
                                <Button variant="contained" color='azul' onClick={() => Navigate("/editar-stock/" + info.codBarras)}>Editar</Button>
                            </Grid>

                        </Grid>
                    ))}

                </Grid>

            </Grid>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
