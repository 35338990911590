import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


import WarehouseIcon from '@mui/icons-material/Warehouse';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';

import logo from '../media/logo-inelvan2.png';
import { getCookie } from '../utils/cookies';
import { getProyectosRecientes, getTrabajosRecientes } from '../services/apis';
import { CrearProyecto } from '../components/CrearProyecto';
import { ListaProyectos } from '../components/ListaProyectos';
import { CerrarSesion } from '../components/Botones/CerrarSesion';



export const Inicio = () => {
    const Navigate = useNavigate();

    let tamIco = 80;
    const token = getCookie('PHPSESSID');
    const [proyectos, setProyectos] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getProyectos = async () => {
        try {
            const response = await getProyectosRecientes();
            console.log("aa", response)
            setProyectos(response.data)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }



    useEffect(() => {
        getProyectos();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load fondo-blanco'
            sx={{ pb: 10 }}
        >
            {/* <Header></Header> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                // gap={4}
                className='margen-top'
            >
                <Grid item xs={8} sm={4} md={3.5} xl={2.5}
                    // onClick={() => Navigate("/")} 
                    className='no-select'
                >
                    <img src={logo} alt="" style={{ maxWidth: '100%' }} />
                </Grid>

                <Grid container xs={11}>
                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    gap={2}
                    style={{ marginBottom: '2em', marginTop: '1em' }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        // onClick={() => Navigate("/crear-trabajo")}
                        onClick={handleClickOpen}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <GroupAddIcon sx={{ fontSize: tamIco }} color='azul' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Crear Proyecto
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Crear proyecto de trabajo
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/proyectos")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <GroupIcon sx={{ fontSize: tamIco }} color='negro' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Ver Proyectos
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Ver proyectos de trabajo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/editar-stock")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <AutoFixHighIcon sx={{ fontSize: tamIco }} color='naranja' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Editar Stock
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Editar el stock de un articulo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/anadir-articulo")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <LibraryAddIcon sx={{ fontSize: tamIco }} color='verde' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Añadir Articulo
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Añadir un nuevo articulo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/ver-stock")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <WarehouseIcon sx={{ fontSize: tamIco }} color='action' />
                        </Grid>
                        <Grid container xs={8} md={8}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Ver Stock
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Ver el stock actual
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        // onClick={() => Navigate("/ver-stock")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <AccessAlarmsIcon sx={{ fontSize: tamIco }} color='disabled' />
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justifyContent="start"
                            alignItems="start"
                            xs={8} sm={9} md={8} xl={9}
                        >
                            <Grid
                                container
                                xs={12}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{ mb: -1 }}
                            >
                                <Typography variant="h6" >
                                    Fichaje
                                </Typography>
                                <Tooltip
                                    title="Informacion sobre el fichaje"
                                    slots={{ transition: Zoom }}
                                    placement="right-start"
                                >
                                    <InfoIcon color='disabled' />
                                </Tooltip>


                            </Grid>
                            <Grid
                                container
                                xs={12}
                                direction="row"
                                justifyContent="start"
                                alignItems="flex-start"
                                sx={{ mb: -1 }}
                            >
                                <Typography variant="subtitle1" className='texto-gris'>
                                    Control de fichajes
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="end"
                            >
                                <Typography variant="caption">
                                    <CircleIcon sx={{ fontSize: 10 }} color='error' /> Activo
                                </Typography>
                                <Button variant="outlined" size='small' color='azul'>Entrada</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    style={{ marginBottom: '1em' }}
                >
                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                        Proyectos recientes
                    </Typography>
                    <div className="separador"></div>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={11}
                style={{ overflow: 'auto', maxHeight: '40vh' }}
                padding={2}
            >
                <ListaProyectos proyectos={proyectos}></ListaProyectos>
            </Grid>

            <CerrarSesion></CerrarSesion>

            <CrearProyecto open={open} setOpen={setOpen}></CrearProyecto>

        </Grid>

    )
}
