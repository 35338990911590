import { Fab, Grid, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../utils/cookies';
import LogoutIcon from '@mui/icons-material/Logout';


export const CerrarSesion = () => {

    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');



    const cerrarSesion = async () => {


        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('code');
        localStorage.removeItem('mail');
        localStorage.removeItem('DesRepresentante');
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


        Navigate("/login");

        try {
            const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
                'token': token,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Respuesta del servidor:', response.data);


        } catch (error) {
            console.error('Error al invalidar el token', error);
        }
    };

    return (
        // <Grid
        //     container
        //     direction="row"
        //     justifyContent="flex-end"
        //     alignItems="flex-end"
        //     xs={2} md={11}
        // >
            <Tooltip title="Cerrar sesión">
                <Fab color='azul' aria-label="Cerrar sesión" style={{ margin: '1em 00.5em', position: 'fixed', bottom: 1, right: 1 }}                >
                    <LogoutIcon onClick={() => cerrarSesion()} />
                </Fab>
            </Tooltip>
        // </Grid>
    )
}
