import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export const BtnAtras = () => {
    const Navigate = useNavigate();


    const goBack = () => {
        window.history.back();
    }


  return (
    <div>
        <Button variant="outlined" color='azul' fontSize="big" onClick={() => goBack()}> <ArrowBackIosIcon fontSize="small" sx={{mb: 0.5}}/> &nbsp;Atras</Button>
    </div>
  )
}
