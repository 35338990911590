import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

export const BtnInicio = () => {

    const Navigate = useNavigate();

    return (
        <div>
            <Button variant="outlined" color='azul' fontSize="big" onClick={() => Navigate("/")}> <HomeIcon fontSize="small" sx={{mb: 0.5}}/> &nbsp;Inicio</Button>
        </div>
    )
}
