import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { Header } from '../components/Header';
import { CerrarSesion } from '../components/Botones/CerrarSesion';
import { SubHeader } from '../components/SubHeader';
import { Camara } from '../components/Camara';
import { getArticulo, updateStock } from '../services/apis';

export const EditarStock = () => {
    const Navigate = useNavigate();
    const param = useParams();

    const [error, setError] = useState(false);
    const [editado, setEditado] = useState(false)
    const [timer, setTimer] = useState(null);

    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [articulo, setArticulo] = useState(
        {
            nombre: '',
            codBarras: '',
            referencia: '',
            precio: null,
            stock: 0
        });
    const [nuevoStock, setNuevoStock] = useState(0);


    const handledOnChange = (event) => {
        const { name, value } = event.target; // Extraer el nombre y el valor del evento

        console.log(value);

        switch (name) {
            case "codBar":
                setArticulo(prevArticulo => ({
                    ...prevArticulo,
                    codBarras: value // Actualiza la propiedad codBarras
                }));
                break;
            case "ref":
                setArticulo(prevArticulo => ({
                    ...prevArticulo,
                    referencia: value // Actualiza la propiedad referencia
                }));
                break;
            case "nombre":
                setArticulo(prevArticulo => ({
                    ...prevArticulo,
                    nombre: value // Actualiza la propiedad nombre
                }));
                break;
            case "precio":
                setArticulo(prevArticulo => ({
                    ...prevArticulo,
                    precio: value
                }));
                break;
            case "stock":
                setArticulo(prevArticulo => ({
                    ...prevArticulo,
                    stock: parseInt(value)
                }));
                break;
            case "cantidad":
                setNuevoStock(value)
                break;
            default:
                break;
        }
    };


    const buscarArticulo = async (evento, value) => {
        try {
            const response = await getArticulo(evento, value);
            console.log("aa", response.data.error)
            if (response.success) {
                setArticulo(response.data[0])
            }
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
            setError(true);
        }

    }

    const editarArticulo = async (articulo) => {

        console.log(articulo.stock);

        try {
            const response = await updateStock(articulo);
            console.log("aa", response)
            if (response.success) {
                setArticulo({
                    nombre: '',
                    codBarras: '',
                    referencia: '',
                    stock: 0
                })
                setEditado(true)

                if (timer) {
                    clearTimeout(timer);
                }

                // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
                setTimer(setTimeout(() => {
                    setEditado(false);

                }, 5000));
            }

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    const cambiarStock = (articulo, nuevoStock) => {
        let temp = { ...articulo }

        if (nuevoStock < 0) {
            temp.stock = parseInt(temp.stock - Math.abs(parseInt(nuevoStock)))

        } else {
            temp.stock = parseInt(temp.stock + parseInt(nuevoStock))
        }


        editarArticulo(temp)
        setArticulo(temp)
    }


    const handleClose = () => {
        setError(false);
    };


    useEffect(() => {
        console.log(param.codBarras);

        if (param.codBarras != null) {
            setArticulo(prevArticulo => ({
                ...prevArticulo,
                codBarras: param.codBarras
            }));
            buscarArticulo("codBar", param.codBarras)
        }
    }, [])



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
            sx={{ pb: 10 }}
        >
            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} sm={11} md={10} xl={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AutoFixHighIcon sx={{ fontSize: 50, m: 1 }} color='naranja' />
                        <Typography variant="h4">
                            Editar Stock
                        </Typography>
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="start"
                    md={11}
                    spacing={1}
                >
                    <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de Barras
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ marginBottom: '1em', padding: '0.9em' }}>
                            <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                        </Button>

                        <Camara
                            camara={camara}
                            setCamara={setCamara}
                            barcode={barcode}
                            setBarcode={setBarcode}
                            buscarArticulo={buscarArticulo}
                            articulo={articulo}
                            setArticulo={setArticulo}
                        ></Camara>

                    </Grid>

                    <Grid item xs={12} md={6} sx={{ minHeight: '49.9vh' }}>

                        <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password">Codigo de Barras</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='codBar'
                                type="text"
                                autoFocus
                                value={articulo.codBarras}
                                onChange={handledOnChange}
                                endAdornment={
                                    <InputAdornment position="end" onClick={() => buscarArticulo("codBar", articulo.codBarras)} >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Codigo de Barras"
                            />
                        </FormControl>
                        <div className="separador-2" style={{ margin: '1em 0em' }}></div>

                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom style={{ textAlign: 'left' }}>
                                Codigo de referencia:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ minWidth: '100%', marginBottom: '0.5em' }} variant="outlined" color='azul'>
                                <InputLabel htmlFor="outlined-adornment-password">Codigo de referencia</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    color='azul'
                                    name='ref'
                                    type="text"
                                    value={articulo.referencia}
                                    onChange={handledOnChange}
                                    endAdornment={
                                        <InputAdornment position="end" onClick={() => buscarArticulo("ref", articulo.referencia)}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                // onClick={console.log("buscar")}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Codigo de referencia"
                                />
                            </FormControl>
                        </Grid>
                        <div className="separador-2"></div>

                        <Grid item xs={12} >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Articulo:&nbsp;
                                {articulo.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl sx={{ minWidth: '100%', marginTop: '0.5em' }} variant="outlined" color='azul'>
                                <InputLabel htmlFor="outlined-adornment-password">Nombre articulo</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    color='azul'
                                    name='nombre'
                                    type="text"
                                    value={articulo.nombre}
                                    onChange={handledOnChange}
                                    endAdornment={
                                        <InputAdornment position="end" onClick={() => buscarArticulo("nombre", articulo.nombre)}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                // onClick={console.log("buscar")}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Codigo de ariculo"
                                />
                            </FormControl>
                        </Grid>


                        <div className="separador" style={{ margin: '0.95em 0em' }}></div>
                        <Grid item xs={12} style={{ textAlign: 'left', color: 'gray' }}>
                            <Typography variant="h5" >
                                Precio Actual (€)
                            </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                // label="Precio Actual"
                                variant="outlined"
                                color='azul'
                                type='number'
                                name='precio'
                                value={articulo.precio}
                                onChange={handledOnChange}
                                inputProps={{ step: 0.01 }}
                            />
                        </Grid>



                        <Grid item xs={12} style={{ textAlign: 'left', color: 'gray' }}>
                            <Typography variant="h5" >
                                Stock Actual
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label={articulo.stock}
                                variant="outlined"
                                color='azul'
                                type='number'
                                // value={articulo.stock}
                                onChange={handledOnChange}
                                disabled
                            />
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            sx={{ mt: 3 }}
                        >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad'
                                    value={nuevoStock}
                                    onChange={handledOnChange}
                                />
                            </Grid>


                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: 'left', marginTop: '0em' }}>
                            <Typography variant="caption" className='texto-gris'>
                                Escribe la cantidad añadida o substraída. (Ej: +5, -10...)
                            </Typography>
                        </Grid>
                    </Grid>


                    <div className="separador-2"></div>

                    <div className="separador"></div>



                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="outlined" size="large" fullWidth className='pointer' onClick={() => Navigate("/")} sx={{ pb: 2, pt: 2 }}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="contained" size="large" fullWidth className='pointer' onClick={() => cambiarStock(articulo, nuevoStock)} sx={{ pb: 2, pt: 2 }}>
                            Cambiar Stock
                        </Button>
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>


            </Grid>

            {/* ---------------------------------------------------------NOTIFICACIONES--------------------------------------------------------------- */}


            <Snackbar
                open={editado}
                // autoHideDuration={6000}
                onClose={handleClose}
                message="Stock modificado con exito."
            // action={action}
            >
                <Alert variant="filled" severity="success" color='azul'>
                    Stock modificado con exito.
                </Alert>
            </Snackbar>




            <Dialog
                open={error}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Este articulo no existe"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Desea crear este articulo en la base de datos?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="contained" onClick={() => Navigate("/anadir-articulo/" + articulo.codBarras)} autoFocus>
                        Crear articulo
                    </Button>
                </DialogActions>
            </Dialog>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
