import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import GroupIcon from '@mui/icons-material/Group';
import LoupeIcon from '@mui/icons-material/Loupe';

import { Header } from '../components/Header';
import { ListaTrabajos } from '../components/ListaTrabajos';
import { getTrabajos } from '../services/apis';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';

export const Trabajos = () => {

    const param = useParams();
    const Navigate = useNavigate();

    const [trabajosFinal, setTrabajosFinal] = useState([]);
    const [trabajos, setTrabajos] = useState([]);
    const [mostrarFinal, setMostrarFinal] = useState(false)
    const [titulo, setTitulo] = useState("");

    const getTrabajo = async (idProyecto) => {
        try {
            const response = await getTrabajos(idProyecto);
            // console.log("aa", response.titulo.nombre)
            setTrabajosFinal(response.data)
            setTrabajos(filtrarTrabajos(response.data))
            setTitulo(response.titulo.nombre)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    const filtrarTrabajos = (array) => {

        array = array.filter(item => item.estado !== "Finalizado");

        return array;
    }

    const handledOnChange = (event) => {
        setMostrarFinal(event.target.checked)
        // console.log(event.target.checked)
    }




    useEffect(() => {
        getTrabajo(param.idProyecto);
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >

            <Header></Header>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                // className='borde-redondo sombra-corta'
                padding={2}
                gap={3}
            >
                <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        xs={12} md={5}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={2} md={2}>
                            <GroupIcon sx={{ fontSize: 50 }} color='negro' />
                        </Grid>

                        <Grid item xs={9} md={8} alignItems="flex-start">
                            <Typography variant="h4" style={{ textAlign: 'left' }}>
                                {titulo}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        xs={12} md={7}
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={1}
                    >
                        <Grid item xs={12} md={3}>
                            <FormControlLabel onClick={handledOnChange} control={<Switch color='azul' />} label="Mostrar finalizados" className='pointer no-select' />
                        </Grid>
                        <Grid item xs={12} md={2.2} >
                            <TextField fullWidth id="outlined-basic" label="Fecha de..." variant="outlined" type='date' onChange={handledOnChange} value="2024-01-01" color='azul' style={{ maxWidth: '90%' }} />
                        </Grid>
                        <Grid item xs={12} md={2.2} >
                            <TextField fullWidth id="outlined-basic" label="Fecha hasta..." variant="outlined" type='date' onChange={handledOnChange} value="2025-01-01" color='azul' style={{ maxWidth: '90%' }} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button fullWidth color='azul' variant="contained" onClick={() => Navigate("/crear-trabajo/" + param.idProyecto)}><LoupeIcon fontSize='small' sx={{ mr: 1, mb: 0.3, pt: 1.2, pb: 1.2 }} /> Crear trabajo</Button>
                        </Grid>
                    </Grid>
                    <div className="separador"></div>
                </Grid>

                {trabajos.length <= 0
                    ? <div>
                        <Typography variant="h4">
                            No hay trabajos
                        </Typography>
                    </div>
                    : <ListaTrabajos trabajos={mostrarFinal ? trabajosFinal : trabajos} titulo={titulo}></ListaTrabajos>
                }



            </Grid>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
