import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';


function valuetext(value) {
    return `${value}`;
}

export const Filtros = ({ filtros, setFiltros }) => {

    const [value, setValue] = React.useState([0, 1000]);
    const [age, setAge] = React.useState('');

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Drawer
            anchor={'left'}
            open={filtros}
            onClose={() => setFiltros(false)}
        >
            <List>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        md={11.5}
                        gap={2}
                        padding={2}
                        style={{ overflow: 'hidden' }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                        >
                            <Typography variant="h4">
                                Filtros:
                            </Typography>
                            <Button variant='text' color='azul' onClick={() => setFiltros(false)}> <ClearIcon sx={{ mb: 0.3 }} /> Cerrar</Button>

                        </Grid>

                        <div className="separador"></div>

                        <Typography variant="subtitle1">
                            Buscar
                        </Typography>
                        <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='buscar'
                                type="text"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            // onClick={console.log("buscar")}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Buscar"
                            />
                        </FormControl>

                        <Typography variant="subtitle1">
                            Filtro de articulos 1
                        </Typography>
                        <Slider
                            getAriaLabel={() => 'Stock range'}
                            value={value}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            color='azul'
                        />

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            md={12}
                        // gap={4}
                        >
                            <Grid item xs={12} md={3}>
                                <TextField id="outlined-basic" label="Min" variant="outlined" value={value[0]} type='number' />
                            </Grid>
                            <Typography variant="subtitle1">
                                Stock
                            </Typography>
                            <Grid item xs={12} md={3}>
                                <TextField id="outlined-basic" label="Max" variant="outlined" value={value[1]} type='number' />
                            </Grid>
                        </Grid>

                        <Typography variant="subtitle1">
                            Filtro de articulos 3
                        </Typography>
                        <FormControlLabel control={<Switch color='azul' />} label="Filtro Switch" />
                        <FormControlLabel control={<Switch color='azul' defaultChecked />} label="Filtro Switch" />
                        <FormControlLabel control={<Switch color='azul' />} label="Filtro Switch" />

                        <Typography variant="subtitle1">
                            Filtro de articulos 4
                        </Typography>
                        <FormControlLabel control={<Checkbox color='azul' defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />
                        <FormControlLabel control={<Checkbox color='azul' sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />
                        <FormControlLabel control={<Checkbox color='azul' defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />

                    </Grid>
                </Grid>

            </List>
        </Drawer>
    )
}
