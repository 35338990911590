import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import GroupIcon from '@mui/icons-material/Group';

import { Header } from '../components/Header';
import { getProyectos } from '../services/apis';
import { ListaProyectos } from '../components/ListaProyectos';
import { SubHeader } from '../components/SubHeader';
import { CerrarSesion } from '../components/Botones/CerrarSesion';


export const Proyectos = () => {


    const [proyectos, setProyectos] = useState([]);
    const [proyectosFinal, setProyectosFinal] = useState([]);
    const [mostrarFinal, setMostrarFinal] = useState(false)


    const handledOnChange = (event) => {
        setMostrarFinal(event.target.checked)
        console.log("proyectosFinal")
    }

    const filtrarProyectos = (array) => {

        array = array.filter(item => item.finalizado !== 1);

        return array;
    }

    const cargarProyectos = async () => {
        try {
            const response = await getProyectos();
            console.log("aa", response.data)
            setProyectosFinal(response.data)
            setProyectos(filtrarProyectos(response.data))
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    useEffect(() => {
        cargarProyectos();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
            sx={{ pb: 10 }}
        >

            <Header></Header>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
            >
                <SubHeader></SubHeader>
            </Grid>



            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={10}
                // className='borde-redondo sombra-corta'
                padding={2}
                gap={3}
            >
                <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        xs={12} md={5}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={2} md={2}>
                            <GroupIcon sx={{ fontSize: 50 }} color='negro' />
                        </Grid>

                        <Grid item xs={9} md={6} alignItems="flex-start">
                            <Typography variant="h4" style={{ textAlign: 'left' }}>
                                Lista de proyectos
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        xs={12} md={5}
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={1}
                    >
                        <Grid item xs={12} md={5.5}>
                            <FormControlLabel onClick={handledOnChange} control={<Switch color='azul' />} label="Mostrar proyectos finalizados" className='pointer no-select' />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <TextField id="outlined-basic" label="Fecha de..." variant="outlined" type='date' onChange={handledOnChange} value="2024-01-01" color='azul' style={{ maxWidth: '90%' }} />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <TextField id="outlined-basic" label="Fecha hasta..." variant="outlined" type='date' onChange={handledOnChange} value="2025-01-01" color='azul' style={{ maxWidth: '90%' }} />
                        </Grid>
                    </Grid>
                    <div className="separador"></div>
                </Grid>
            </Grid>

            <ListaProyectos proyectos={mostrarFinal ? proyectosFinal : proyectos}></ListaProyectos>

            <CerrarSesion></CerrarSesion>
        </Grid>
    )
}
