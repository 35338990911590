import React from 'react'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { BtnAtras } from './Botones/BtnAtras';
import { BtnInicio } from './Botones/BtnInicio';

export const SubHeader = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            sx={{ mt: 0, mb: 1 }}
        >
            <BtnAtras></BtnAtras>

            <BtnInicio></BtnInicio>
        </Grid>
    )
}
